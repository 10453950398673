import React, {useEffect, useState, forwardRef, useRef} from 'react';
import ReactDOM from 'react-dom/client';

import Carousel from 'react-multi-carousel';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {publicIpv4} from 'public-ip';
import axios from "axios";

import 'react-multi-carousel/lib/styles.css';
import './App.sass';


const fpPromise = FingerprintJS.load()
const md5 = require('md5');

const Slider = (props) => {
    const {background, items, width, onClickSliderItem} = props;
    return (
        <div key={background} className="slider" style={{background: `url("${background}")`}}>
            <Carousel
                additionalTransfrom={0}
                arrows={width > 550 ? true : false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={width < 550 ? true : false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {
                    items.map((item, index) => {
                        return <img src={width > 550 ? item.image : item.image_mobile}
                                    alt="slide"
                                    className="slider__item__content__right__img" style={{maxWidth: "70%"}}
                                    // Накинул обработчик события
                                    onClick={onClickSliderItem}
                                />
                    })
                }
            </Carousel>
        </div>
    )
}

// Добавил forwardRef
const Form = forwardRef((props, ref) => {
    const {
        background,
        button,
        button_resp,
        fields,
        fields_resp,
        center_background,
        center_background_resp,
        background_mobile,
        pixel_name_class,
        width,
        mobile_form_center,
        promo,
        domen,
        domen_resp,
        show_app,
        country,
        currency
    } = props;
    const [visitorId, setVisitorId] = useState('')
    const [ipAddress, setIpAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [dataReg, setDataReg] = useState()
    useEffect(() => {
        async function fetchData() {
            // Get the visitor identifier when you need it.
            const fp = await fpPromise
            const result = await fp.get()
            const ip_address = await publicIpv4()
            setVisitorId(result.visitorId)
            setIpAddress(ip_address);

        }
        fetchData();
    }, []);

    async function countConvs() {
        if (localStorage.getItem("evadav") !== null) {
            const res = await axios.get('https://evadav.com/phpb', {
                params: {
                    "click_id": localStorage.getItem("evadav"),
                    "payout": 0,
            }});
        }
	}
    const FormSend = (e) => {
        e.preventDefault();
        setLoading(true)
        e.target.querySelector("button[type='submit']")
        const email = e.target[0].value;
        countConvs();
        fetch(`${domen_resp}/reg?data=${JSON.stringify({
            domen: `${domen}/web-api`,
            headers: {
                'accept': 'application/json',
                'X-Client-Ip': ipAddress,
                'X-Visitor-Id': visitorId,
                'Content-Type': 'application/json'
            },
            body: {
                "id": 4,
                "sign": md5("oShJVMZGiCcdGIFKN48Wmc9vfFf5z3El" + 4 + email),
                "email": email,
                "country": country,
                "currency": currency,
                "send_reg_data": true,
                "bonus_choice": 0,
                "need_parse_phone": true,
                "vip": false,
                "promocode": promo
            }
        })}`, {
            method: "GET",
        })
            .then(respone => {
                if (respone.status === 200) {
                    return respone.json()
                } else alert("错误")
            })
            .then(data => {
                setDataReg(data)
                show_app(true)
            })
            .catch(response => {
                alert("错误")
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <form onSubmit={FormSend} action="" className="form"
              style={{background: `url("${width >= 550 ? background : background_mobile}")`}}>
            {    // Накинул ref
                dataReg ? <div ref={ref} className="form__content"
                               style={{background: `url("${width >= 550 ? center_background_resp : mobile_form_center}")`}}>
                    <div className="vertical">
                        {
                            fields_resp.map((item, index) => {
                                if (item.type === "checkbox") {
                                    return <div className='form__field' key={index}>{item.title}</div>
                                } else {
                                    return <div className='form__field' key={index}>
                                        <label htmlFor={item.name} className="form__content__title">{item.title}</label>
                                        <input id={item.name} className="form__content__input" type={item.type}
                                               placeholder={item.placeholder} value={item.name === "login" ? dataReg.login : dataReg.password}/>
                                    </div>
                                }
                            })
                        }
                        <button style={{width: "50px", height: "50px", background: "#14A0FF", borderRadius: "5px", marginTop: "auto", border: "none"}} onClick={(e) => {
                            e.preventDefault()
                            navigator.clipboard.writeText(`${dataReg.login} ${dataReg.password}`);
                        }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z" stroke="white" stroke-width="1.5"/>
                                <path d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5" stroke="white" stroke-width="1.5"/>
                            </svg>
                        </button>
                    </div>
                    <button className="mobile-button-to-url"  onClick={(e) => {
                        e.preventDefault()
                        window.open(`https://${dataReg.domain}/web-api/${dataReg.deposit}`, '_blank')
                    }}>{button_resp.name}
                    </button> {/* Накинул ref */}
                </div> : <div  ref={ref} className="form__content"
                           style={{background: `url("${width >= 550 ? center_background : mobile_form_center}")`}}>
                    {
                        fields.map((item, index) => {
                            if (item.type === "checkbox") {
                                return <div className='form__field' key={index}>{item.title}</div>
                            } else {
                                return <div className='form__field' key={index}>
                                    <label htmlFor={item.name} className="form__content__title">{item.title}</label>
                                    <input id={item.name} className="form__content__input" type={item.type}
                                           placeholder={item.placeholder}/>
                                </div>
                            }
                        })
                    }
                    <button type="submit" className={pixel_name_class}>{
                        loading ? <div className="gooey">
                                <span className="dot"></span>
                                <div className="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div> :
                        button.name
                    }</button>
                </div>
            }
        </form>
    )
})

function App() {
    const [data, setData] = useState();
    const [width, setWidth] = useState(window.innerWidth);
    const [showApp, setShowApp] = useState(true);
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };
    // Создал ref контейнер
    const formRef = useRef(null)
    // Создал функцию для скролла
    const onClickSliderItem = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });
    useEffect(() => {
        fetch("./main-data.json")
            .then(response => response.json())
            .then(data => setData(data));
    }, []);

    function getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "all";
    }

    if (data) {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={data?.header.logo} className="App-logo" alt="logo"/>
                    {
                        width > 550 &&
                        <div className="menu">
                        </div>
                    }
                </header>
                <div className="main">
                    {/* Прокинул функцию для скролла */}
                    <Slider {...data?.body.slider} width={width} onClickSliderItem={onClickSliderItem} />
                    {/* Прокинул ref */}
                    <Form ref={formRef} {...data?.body.form} width={width} show_app={setShowApp}/>
                </div>
                {
                    showApp && <div className="end_block" style={{zIndex: 1}}>
                        <img src={width >= 550 ? data?.body.endBlock.background : data?.body.endBlock.background_mobile}
                             alt="qr code" style={{width: "100%", marginTop: '-200px'}}/>
                        {/*<img src={data?.body.endBlock.qr} alt="qr" style={{marginTop: "-7.5%", width: "140px", height: "140px"}}/>*/}
                        <button onClick={() => {
                            if(getMobileOperatingSystem() === "Android"){
                                window.open(data?.body.endBlock.button.android_url, '_blank')
                            } else {
                                window.open(data?.body.endBlock.button.url, '_blank')
                            }
                        }}>{data?.body.endBlock.button.name}</button>
                    </div>
                }
                <footer className="App-footer"
                        style={{background: `url("${width >= 550 ? data?.footer.background : data?.footer.mobile_background}")`, zIndex: 0, backgroundSize: "100% 70%", backgroundRepeat: "no-repeat", backgroundPosition: "bottom"}}>
                    <div className="footer__content">
                        <div className="logos">
                            {data?.footer.logos.map((item, index) => (
                                <img className="logo" key={index} src={item.image} alt="logo"/>
                            ))}
                        </div>
                        <div className="social">
                            {data?.footer.social.map((item, index) => (
                                <a key={index} href={item.url} target="_blank">
                                    <img src={item.image} alt={item.name}/>
                                    <h6>{item.name}</h6>
                                </a>
                            ))}
                        </div>
                        <h4>{data.footer.text}{new Date().getFullYear()}</h4>
                    </div>
                </footer>
            </div>
        );
    }
    return null;
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);
